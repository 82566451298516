import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { css } from "@emotion/core";
import { GridLoader } from 'react-spinners';
import './styles/profile.css';

const override = css`
  display: block;
  margin: 0 auto;
  line-height: 0;
`;

 class Profile extends Component {
  constructor() {
    super();
    this.state = {
      isActive: false,
      firstname: "", 
      lastname: "",
      email: "",
      address: "",
      phone_number:"",
      isLoading: false,
    };
  }
  toggleSidebar(e) {
    this.setState({
      isActive: !this.state.isActive,
    });
  }
  componentDidMount() {
    let id = window.sessionStorage.getItem("id");
    this.setState({
      isLoading:true,
    });
    //axios.get(`https://api.cloudfinish.io/api/getprofile/?user_id=`+ id)
    axios.get(`https://api.cloudstruck.io/api/getprofile/?user_id=`+ id)
      .then(res => {
        this.setState({
          isLoading:false,
        });
        const person = res.data.data;
        console.log(JSON.stringify(person));
        this.setState({
          firstname: person[0].first_name, //response.data.data.name,
          lastname: person[0].last_name,
          email: person[0].email,
          address: person[0].address,
          phone_number: person[0].phone_number,
        });
      })
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="profile-wrapper col-lg-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <h1 className="p-m-t" >Welcome {this.state.isLoading?"User": this.state.firstname},</h1>
              <div className={this.state.isLoading?"outer-loader":"d-n"}>
                  <div className="inner-loader">
                      <GridLoader
                          css={override}
                          size={15}
                          margin={4}
                          color={"#0f83ff"}
                          loading={this.state.isLoading}
                      />
                  </div>
              </div>
              <div className="profile-container">
                <span>
                    <p className="profile-title"> Profile</p>
                    <p className="second-heading"> Basic Information and contact information</p>
                </span>
                <div className="item-wrapper">
                    <div className="profile-items">
                        <p className="item-title"> First Name</p>
                        <p className="item-value">
                            {this.state.isLoading?"loading...": this.state.firstname}
                        </p>
                    </div>
                    <div className="profile-items">
                        <p className="item-title"> Last Name</p>
                        <p className="item-value">
                            {this.state.isLoading?"loading...":this.state.lastname}
                        </p>
                    </div>
                    <div className="profile-items">
                        <p className="item-title"> Address</p>
                        <p className="item-value">
                            {this.state.isLoading?"loading...":this.state.address}
                        </p>
                    </div>
                    <div className="profile-items">
                        <p className="item-title"> Email</p>
                        <p className="item-value">
                            {this.state.isLoading?"loading...":this.state.email}
                        </p>
                    </div>
                    <div className="profile-items">
                        <p className="item-title"> phone</p>
                        <p className="item-value">
                            {this.state.isLoading?"loading...":this.state.phone_number}
                        </p>
                    </div>
                </div>

              </div>





              {/*

           <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label style={{color:"#9E9E9E"}}>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstname"
                    id="firstname"
                    value={this.state.firstname}
                    onChange={this.onChange} required
                    placeholder="First Name"
                    data-msg=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                <label style={{color:"#9E9E9E"}}>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastname"
                    value={this.state.lastname}
                    onChange={this.onChange} required
                    id="lastname"
                    placeholder="Last Name"
                    data-msg=""
                  />
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-6">
              <div className="form-group">
              <label style={{color:"#9E9E9E"}}>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange} required
                    id="email"
                    placeholder="Email"
                    data-msg="Please enter a valid email"
                  />
                </div>
                </div>
                <div className="col-md-6">
                <div className="form-group">
                <label style={{color:"#9E9E9E"}}>Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    value={this.state.address}
                    onChange={this.onChange} required
                    id="address"
                    placeholder="Address"
                    data-msg="Please enter a valid address"
                  />
                </div>
                </div>
            </div>
            <div className="row">
            <div className="col-md-6">
              <div className="form-group">
              <label style={{color:"#9E9E9E"}}>Contact Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone_number"
                    value={this.state.phone_number}
                    onChange={this.onChange} required
                    id="phone_number"
                    placeholder="phone_number"
                    data-msg="Please enter a valid contact number"
                  />
                </div>
                </div>
                <div className="col-md-6">
                </div>
            </div>

              */}

          </div>
  </div>
  </>
    );
  }
}
export default  withRouter(Profile);

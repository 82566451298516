import React, { Component } from "react";
import '../App.css';
import SwaggerUi, {presets} from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import { css } from "@emotion/core";
import { GridLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  line-height: 0;
`;

class SwaggerAPI extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
      }
        componentDidMount() {
          this.setState({
            isLoading:true,
          });
          SwaggerUi({
            dom_id: '#swaggerContainer',
            //url: `/cloudfinish-api-0.1.json`,
            url: `/cloudstruck-api-0.1.json`,
            presets: [presets.apis],
          });
          setTimeout(() => {
            this.setState({
              isLoading:false,
            });                
}, 500);
      }
    
      render() {
        return (
          <>
              <div className={this.state.isLoading?"outer-loader":"d-n"}>
                  <div className="inner-loader">
                      <GridLoader
                          css={override}
                          size={15}
                          margin={4}
                          color={"#0f83ff"}
                          loading={this.state.isLoading}
                      />
                  </div>
              </div>
         <div id="swaggerContainer" style={{visibility: this.state.isLoading ? 'hidden' : 'visible' }} />
         </>
        );
      }
    }
    export default SwaggerAPI;
